/**
* The state object passed to IDPs
*/
export interface IdpState {
  /**
  * Random string
  */
  random: string,
  
  /**
  * Optional callback path
  */
  callback?: string
  
  /**
  * Optional cta action
  */
  cta?: string;
}

/**
* Encode the given state object
* @param state 
*/
export const encodeState = (state: IdpState): string => {
  return btoa(JSON.stringify(state));
}

/**
* Decode the given state string.
* 
* @param encodedState The encoded state to decode
*/
export const decodeState = (encodedState: string): IdpState => {
  return JSON.parse(atob(encodedState));
}