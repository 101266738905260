
import { derived, writable, get } from 'svelte/store';
import type { Readable, Writable } from 'svelte/store';
import { OktaAuth } from '@okta/okta-auth-js'
import type { AuthState, UserClaims, OktaAuthOptions } from '@okta/okta-auth-js';
import type { IAuthStore, IOktaAuthConfig, IUserInfo, OnActivateFn, OnAuthUpdatedCallbackFn, OnSignoutCallbackFn, OnSignupCallbackFn, OnUpdateUserProfileFn } from '../types'
import { AuthError, AuthErrorStatus } from '../types'
import { encodeState, decodeState } from '../state';
import type { IdpState } from '../state';
import { isMobile } from '../../responsive';
import type { Page } from '@sveltejs/kit';

type OnAuthRequiredCallbackFn = (oktaAuth: OktaAuth) => Promise<void> | void;

interface OktaSvelteOptions {
  oktaAuth?: OktaAuth;
  onAuthRequiredCallbackFn: OnAuthRequiredCallbackFn;
  onAuthResumeCallbackFn: OnAuthRequiredCallbackFn;
  onAuthUpdatedCallbackFn?: OnAuthUpdatedCallbackFn;
  onSignupCallbackFn?: OnSignupCallbackFn;
  onActivateFn: OnActivateFn;
  onUpdateUserProfileFn: OnUpdateUserProfileFn;
  onSignoutCallbackFn?: OnSignoutCallbackFn;
}

/**
* Okta specific implementation of IAuthStore
*/
export class OktaSvelteStore implements IAuthStore {
  // singleton instance 
  private static _oktaSvelteStore: OktaSvelteStore | null;

  // OpenID Connect parameters from dotenv environment variables
  private static _issuer: string;
  private static _domain: string;
  private static _clientId: string;
  private static _redirectUri: string;
  private static _scopes: string[];

  private _oktaAuth: OktaAuth | null;
  private _authState: Writable<AuthState | null>;
  private _userInfo: Writable<IUserInfo | null>;
  private _onAuthRequiredCallbackFn: OnAuthRequiredCallbackFn | undefined;
  private _onAuthResumeCallbackFn: OnAuthRequiredCallbackFn | undefined;
  private _state: string | null;
  private _nonce: string | null;
  private _onAuthUpdatedCallbackFn: OnAuthUpdatedCallbackFn | undefined;
  private _onSignupCallbackFn: OnSignupCallbackFn | undefined;
  private _onActivateFn: OnActivateFn;
  private _onUpdateUserProfileFn: OnUpdateUserProfileFn;
  private _onSignoutCallbackFn: OnAuthUpdatedCallbackFn | undefined;

  private static _config: IOktaAuthConfig | null = null;

  private static async onHandleAuthStateUpdate(authState: AuthState) {
    (<any>OktaSvelteStore.getInstance() as OktaSvelteStore)._authState.update(() => authState)
  }

  /**
   * Configure the OktaSvelteStore singleton instance.
   * 
   * @param config The configuration object
   * 
   * @returns The svelte okta auth store instance
   */
  public static configure(config: IOktaAuthConfig): void {
    if (OktaSvelteStore._config) {
      // throw new Error('DbUtils has already been configured.');
      return;
    }
    OktaSvelteStore._config = {
      ...config
    }
  }

  /**
  * Get the svelte okta auth store singleton instance.
  * 
  * @returns The svelte okta auth store instance
  */
  public static getInstance(): IAuthStore {

    if (!OktaSvelteStore._config) {
      throw new Error('OktaSvelteStore has not been configured yet.');
    }

    OktaSvelteStore._issuer = OktaSvelteStore._config.issuer;
    OktaSvelteStore._domain = OktaSvelteStore._config.domain;
    OktaSvelteStore._clientId = OktaSvelteStore._config.clientId;
    OktaSvelteStore._redirectUri = OktaSvelteStore._config.redirectUri;

    OktaSvelteStore._scopes = ['openid', 'profile', 'email', 'phone', 'address', 'offline_access'];

    if (OktaSvelteStore._oktaSvelteStore == null) {

      if (typeof window !== 'undefined') {
        const config: OktaAuthOptions = {
          clientId: OktaSvelteStore._clientId,
          issuer: OktaSvelteStore._issuer,
          redirectUri: OktaSvelteStore._redirectUri,
          scopes: OktaSvelteStore._scopes,
          pkce: true,
          //devMode: true, // TODO: uncomment to run in dev mode and log more info
          services: {
            autoRenew: true,
            autoRemove: false,
            syncStorage: true,
          },
          tokenManager: {
            autoRenew: true,
            autoRemove: false
          }
        };

        const oktaAuth = new OktaAuth(config);

        OktaSvelteStore._oktaSvelteStore = new OktaSvelteStore({
          oktaAuth: oktaAuth,
          onAuthRequiredCallbackFn: () => { },
          onAuthResumeCallbackFn: () => { },
          onAuthUpdatedCallbackFn: OktaSvelteStore._config.onAuthUpdatedCallbackFn,
          onSignupCallbackFn: OktaSvelteStore._config.onSignupCallbackFn,
          onActivateFn: OktaSvelteStore._config.onActivateFn,
          onUpdateUserProfileFn: OktaSvelteStore._config.onUpdateUserProfileFn,
          onSignoutCallbackFn: OktaSvelteStore._config.onSignoutCallbackFn
        } as OktaSvelteOptions);
      } else {
        OktaSvelteStore._oktaSvelteStore = new OktaSvelteStore({
          onAuthRequiredCallbackFn: () => { },
          onAuthResumeCallbackFn: () => { },
          onAuthUpdatedCallbackFn: OktaSvelteStore._config.onAuthUpdatedCallbackFn,
          onSignupCallbackFn: OktaSvelteStore._config.onSignupCallbackFn,
          onActivateFn: OktaSvelteStore._config.onActivateFn,
          onUpdateUserProfileFn: OktaSvelteStore._config.onUpdateUserProfileFn,
          onSignoutCallbackFn: OktaSvelteStore._config.onSignoutCallbackFn
        } as OktaSvelteOptions)
      }
    }
    return OktaSvelteStore._oktaSvelteStore;
  }

  /**
   * Construct a new instance
   * @param param The initialization parameters
   */
  private constructor({
    oktaAuth,
    onAuthRequiredCallbackFn,
    onAuthResumeCallbackFn,
    onAuthUpdatedCallbackFn,
    onSignupCallbackFn,
    onActivateFn,
    onUpdateUserProfileFn,
    onSignoutCallbackFn
  } = {} as OktaSvelteOptions) {

    if (!OktaSvelteStore._config) {
      throw new Error('OktaSvelteStore has not been configured yet.');
    }

    this._onAuthRequiredCallbackFn = onAuthRequiredCallbackFn;
    this._onAuthResumeCallbackFn = onAuthResumeCallbackFn;
    this._onAuthUpdatedCallbackFn = onAuthUpdatedCallbackFn;
    this._onSignupCallbackFn = onSignupCallbackFn;
    this._onSignoutCallbackFn = onSignoutCallbackFn;
    this._onActivateFn = onActivateFn;
    this._onUpdateUserProfileFn = onUpdateUserProfileFn;

    if (oktaAuth) {
      this._oktaAuth = oktaAuth;
      this._authState = writable(null);
      oktaAuth.start().then(() => {
        this._authState.set(oktaAuth.authStateManager.getAuthState());
      });
      this._userInfo = writable(null)
    } else {
      this._oktaAuth = null;
      this._authState = writable(null);
      this._userInfo = writable(null)
    }

    // generate random string as nonce and state
    this._nonce = (0 | Math.random() * 9e6).toString(36)
    this._state = (0 | Math.random() * 9e6).toString(36)
  }

  buildSocialLoginAuthorizeUrl(): string {
    throw new Error('Method not implemented.');
  }

  private _mapClaimsToUserInfo = async (userClaims: UserClaims): Promise<IUserInfo> => {
    try {
      const userData = await fetch(OktaSvelteStore._domain + "/api/v1/users/me", {
        credentials: 'include'
      }).then(data => data.json());

      if (userData && userData.profile) {

        return {
          bsid: userData.profile.bsId ? userData.profile.bsId : "",
          id: userData.id ? userData.id : "",
          name: userData.profile.firstName ? userData.profile.firstName : "",
          surname: userData.profile.lastName ? userData.profile.lastName : "",
          fullname: userClaims.name ? userClaims.name : "",
          email: userData.profile.email ? userData.profile.email : userClaims.email ? userClaims.email : "",

          phone: userData.profile.mobilePhone ? userData.profile.mobilePhone : userClaims.phone_number ? userClaims.phone_number : userClaims.mobilePhone ? userClaims.mobilePhone : "",
          subscribe: userData.profile.consensoMailingBS ? userData.profile.consensoMailingBS == '1' : userClaims.consensoMailingBS ? userClaims.consensoMailingBS == '1' : false,
          profilingConsent: userData.profile.consensoProfilazione ? userData.profile.consensoProfilazione == '1' : userClaims.consensoProfilazione ? userClaims.consensoProfilazione == '1' : false,
          businessUseConsent: userData.profile.consensoSocietaBS ? userData.profile.consensoSocietaBS == '1' : userClaims.consensoSocietaBS ? userClaims.consensoSocietaBS == '1' : false,

          birthday: userData.profile.dataNascita ? userData.profile.dataNascita : "",
          city: userData.profile.city ? userData.profile.city : userClaims.city ? userClaims.city : "",
          address: userData.profile.streetAddress ? userData.profile.streetAddress : userClaims.address ? userClaims.address.toString() : "",
          postalCode: userData.profile.zipCode ? userData.profile.zipCode : userClaims.city ? userClaims.city : userClaims.zipCode ? userClaims.zipCode : "",
          organization: userData.profile.organization ? userData.profile.organization : userClaims.organization ? userClaims.organization : "",
          sector: userData.profile.settore ? userData.profile.settore : userClaims.settore ? userClaims.settore : "",
          function: userData.profile.funzioneAziendale ? userData.profile.funzioneAziendale : userClaims.function ? userClaims.function : "",
          graduation: userData.profile.graduation ? userData.profile.graduation : ""
        }
      }
    } catch (e) {
      console.error("Error fetching user data", e);
      throw e;
    }

    // from data to string YYYYMMDD
    function dateToYYYYMMDD(date: string | Date): string | undefined {
      if (!date || (date && typeof date === 'string')) {
        return date;
      }

      if (date && date instanceof Date) {
        return date.getFullYear() + ("0" + (date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2);
      }
    }

    return {
      bsid: "BS" + userClaims.sub,
      id: userClaims.sub,
      name: userClaims.given_name || '',
      surname: userClaims.family_name || '',
      fullname: userClaims.name || '',
      email: userClaims.email || '',
      phone: userClaims.phone_number ? userClaims.phone_number.toString() : userClaims.mobilePhone ? userClaims.mobilePhone.toString() : "",
      subscribe: typeof userClaims.consensoMailingBS === 'boolean' ? userClaims.consensoMailingBS : userClaims.consensoMailingBS == '1',
      profilingConsent: typeof userClaims.consensoProfilazione === 'boolean' ? userClaims.consensoProfilazione : userClaims.consensoProfilazione == '1',
      businessUseConsent: typeof userClaims.consensoSocietaBS === 'boolean' ? userClaims.consensoSocietaBS : userClaims.consensoSocietaBS == '1',
      birthday: userClaims.dataNascita && typeof userClaims.dataNascita === 'string' ? dateToYYYYMMDD(userClaims.dataNascita) : '',
      city: userClaims.city ? userClaims.city.toString() : '',
      address: userClaims.address ? userClaims.address.toString() : '',
      postalCode: userClaims.zipCode ? userClaims.zipCode.toString() : '',
      organization: userClaims.organization ? userClaims.organization.toString() : '',
      sector: userClaims.settore ? userClaims.settore.toString() : '',
      function: userClaims.function ? userClaims.function.toString() : '',
      //graduation: userClaims.graduation // FIXME: decode correctly from user claims
    }
  }

  /**
  * Start the auth state manager.
  */
  public async start() {

    if (this._oktaAuth) {
      const oktaAuth = this._oktaAuth;
      await oktaAuth.start();

      this._authState.set(oktaAuth.authStateManager.getAuthState());

      oktaAuth.setOriginalUri("/")

      // subscribe to the latest authState
      oktaAuth.authStateManager.subscribe(OktaSvelteStore.onHandleAuthStateUpdate)

      if (!oktaAuth.token.isLoginRedirect()) {
        // Calculates initial auth state and fires change event for listeners
        // Also starts the token auto-renew service

        const authenticated = await oktaAuth.isAuthenticated();

        if (authenticated) {
          // if authenticated reload user details using saved tokens
          oktaAuth.token.getUserInfo().then(async (userClaims) => {
            this._userInfo.set(await this._mapClaimsToUserInfo(userClaims))
          })
        }
      }
    }
  }

  /**
  * Stop the auth state manager.
  */
  public async stop() {
    if (this._oktaAuth) {
      this._oktaAuth.authStateManager.unsubscribe(OktaSvelteStore.onHandleAuthStateUpdate)
      await this._oktaAuth.stop()
    }
  }

  /**
  * Check if user is logged in.
  */
  public get isLoggedIn(): Readable<boolean> {
    return derived(this._authState, $authState => this._oktaAuth != null && ($authState != null) && ($authState.isAuthenticated != undefined) && $authState.isAuthenticated, false)
  }

  /**
  * Get information about current logged in user.
  */
  public get userInfo(): Readable<IUserInfo | null> {
    return derived([this._authState, this._userInfo], $v => this._oktaAuth && $v[0] && $v[0].isAuthenticated ? $v[1] : null, null)
  }

  /**
  * Get the access token
  */
  public get accessToken(): string | null {
    const accessToken = this._oktaAuth ? this._oktaAuth.getAccessToken() : null;
    return accessToken ? accessToken : null;
  }

  /**
  * Handles the login redirect call
  * 
  * @param page The calling page
  */
  public async handleLoginRedirect(page: Readable<Page>): Promise<IdpState | undefined> {

    if (this._oktaAuth) {
      try {

        const state = <string>get(page).url.searchParams.get('state');
        //const code = <string>  get(page).query.code;

        const res = await this._oktaAuth.token.parseFromUrl();

        this._oktaAuth.tokenManager.setTokens(res.tokens);

        const userClaims = await this._oktaAuth.token.getUserInfo();
        const userInfo = await this._mapClaimsToUserInfo(userClaims);
        this._userInfo.set(userInfo)

        // call the signup callback function
        if (this._onSignupCallbackFn) {
          await this._onSignupCallbackFn(userInfo);
        }

        // call the user updated callback function
        if (this._onAuthUpdatedCallbackFn) {
          await this._onAuthUpdatedCallbackFn();
        }

        let decodedState: IdpState = { random: this._state || '' };
        if (state) {
          decodedState = decodeState(state);
        }

        return decodedState;

      } catch (error: any) {
        if (this._oktaAuth.idx.isInteractionRequiredError(error)) {
          // user interaction is required eventually call callback functions
          const callbackFn = this._onAuthResumeCallbackFn || this._onAuthRequiredCallbackFn;
          if (callbackFn) {
            callbackFn(this._oktaAuth);
            return;
          }
        } else {
          // TODO: no user interaction is required, what to do?
          throw (error);
        }
      }
    } else {
      console.error("No okta auth object, maybe are you server side?");
      throw new Error("No okta auth object, maybe are you server side?")
    }
  }

  /**
  * Complete the user activation with given token.
  * 
  * @param token The activation token.
  * @param password The password to be set.
  * 
  * @returns True on successful sign in.
  */
  public async signInWithToken(token: string, password: string): Promise<boolean> {


    if (this._oktaAuth) {
      // call the activation function
      const activateResult = await this._onActivateFn(token, password);

      await this.signIn(activateResult.username, activateResult.password);

      return true;
    } else {
      console.error("No okta auth object, maybe are you server side?");
      throw new Error("No okta auth object, maybe are you server side?")
    }
  }

  /**
  * Sign in user.
  * 
  * @param username The username.
  * @param password The password.
  * 
  * @throws AuthError
  */
  public async signIn(username: string, password: string): Promise<void> {

    if (this._oktaAuth) {
      const transaction = await this._oktaAuth.signInWithCredentials({ username, password });

      if (transaction.status === 'SUCCESS' || transaction.status === 'PASSWORD_WARN') {
        const res = await this._oktaAuth.token.getWithoutPrompt({
          sessionToken: transaction.sessionToken,
          responseType: 'id_token',
          scopes: OktaSvelteStore._scopes
        });

        this._oktaAuth.tokenManager.setTokens(res.tokens);

        const userClaims = await this._oktaAuth.token.getUserInfo()

        this._userInfo.set(await this._mapClaimsToUserInfo(userClaims))

        // call the auth updated callback function
        if (this._onAuthUpdatedCallbackFn) {
          await this._onAuthUpdatedCallbackFn();
        }

        return;
      } else {
        // map statuses from transaction to error, so UI could take care of special cases
        const status = transaction.status === 'LOCKED_OUT' ? AuthErrorStatus.LOCKED_OUT :
          transaction.status === 'PASSWORD_EXPIRED' ? AuthErrorStatus.PASSWORD_EXPIRED :
            AuthErrorStatus.WRONG_PASSWORD;

        throw new AuthError("Okta reported " + transaction.status, status)
      }

    } else {
      console.error("No okta auth object, maybe are you server side?");
      throw new Error("No okta auth object, maybe are you server side?")
    }
  }

  public async signInWithIdp(idp: string, redirect: boolean = false, callback?: string, cta?: string, isSocialLogin?: boolean): Promise<void> {
    // if present encode callback in state
    const state = encodeState({
      random: this._state || "",
      callback: callback,
      cta: cta
    })

    if (this._oktaAuth) {
      const oktaAuth = this._oktaAuth;
      if (!redirect && !isMobile.iOS()) {
        try {
          const res = await this._oktaAuth.token.getWithPopup({
            idp: idp,
            scopes: OktaSvelteStore._scopes,
            prompt: 'consent login',
            state: state,
            nonce: this._nonce ? this._nonce : undefined,
            display: 'popup'
          });

          oktaAuth.tokenManager.setTokens(res.tokens);

          const userClaims = await oktaAuth.getUser();

          const userInfo = await this._mapClaimsToUserInfo(userClaims);
          this._userInfo.set(userInfo);


          // call the callback function
          if (this._onSignupCallbackFn) {
            await this._onSignupCallbackFn(userInfo);
          }

          // call the auth updated callback function
          if (this._onAuthUpdatedCallbackFn) {
            await this._onAuthUpdatedCallbackFn();
          }
        } catch (error) {

          console.error("Error during social login", error);

          throw error;
        }
      } else {
        // call the auth updated callback function
        if (this._onAuthUpdatedCallbackFn) {
          await this._onAuthUpdatedCallbackFn();
        }

        try {
          await this._oktaAuth.token.getWithRedirect({
            idp: idp,
            scopes: OktaSvelteStore._scopes,
            prompt: 'consent login',
            state: state,
            nonce: this._nonce ? this._nonce : undefined,
            redirectUri: OktaSvelteStore._redirectUri + (isSocialLogin ? "_social" : "")
          });

          await setTimeout(() => { }, 10000);
        } catch (error) {
          throw new Error("Error during social login");
        }
      }
    } else {
      throw new Error("No okta auth object, maybe are you server side?")
    }
  }

  /**
  * Logout the user (if logged in).
  * 
  * @param callbackFn The callback function to be called before the sign out.
  * 
  * @return A promise resolved whe operation is completed.
  */
  public async signOut(): Promise<void> {
    if (this._oktaAuth) {

      await this._oktaAuth.revokeAccessToken();

      if (await this._oktaAuth.session.exists()) {
        try {
          await this._oktaAuth.closeSession();
        } catch (e: any) {
          if (e.xhr && e.xhr.status === 429) {
            throw new Error("Too many requests");
          } else {
            throw e;
          }
        }
      }

      // call the auth updated callback function
      if (this._onAuthUpdatedCallbackFn) {
        await this._onAuthUpdatedCallbackFn();
      }

      // call the signout callback function
      if (this._onSignoutCallbackFn) {
        await this._onSignoutCallbackFn();
      }
    } else {
      console.error("No okta auth object, maybe are you server side?");
      throw new Error("No okta auth object, maybe are you server side?");
    }
  }

  /**
  * Send forgot password generation link.
  * 
  * @param username The username, without domain or fully qualified.
  * 
  * @returns  A promise resolved whe operation is completed.
  */
  public async forgotPassword(username: string): Promise<void> {
    if (this._oktaAuth) {
      const transaction = await this._oktaAuth.forgotPassword({
        username: username,
        factorType: "EMAIL"
      })

      if (transaction.status === "SUCCESS") {
        // TODO: forgot password waits until termination of transaction (for example for SM confirmation code), so just skip
      }
    } else {
      console.error("No okta auth object, maybe are you server side?");
      throw new Error("No okta auth object, maybe are you server side?")
    }
  }

  /**
  * Send unlock link.
  * 
  * @param username The username, without domain or fully qualified.
  * 
  * @returns  A promise resolved whe operation is completed.
  */
  public async unlock(username: string): Promise<void> {
    if (this._oktaAuth) {
      const transaction = await this._oktaAuth.unlockAccount({
        username: username,
        factorType: "EMAIL"
      });

      if (transaction.status === "SUCCESS") {
        // TODO: unlock waits until termination of transaction (for example for SM confirmation code), so just skip
      }

    } else {
      console.error("No okta auth object, maybe are you server side?");
      throw new Error("No okta auth object, maybe are you server side?")
    }
  }

  /**
   * Retrieve user details
   * 
   */
  public async retrieveUser(): Promise<void> {
    if (this._oktaAuth) {
      const userClaims = await this._oktaAuth.token.getUserInfo();

      this._userInfo.set(await this._mapClaimsToUserInfo(userClaims))

      // call the auth updated callback function
      if (this._onAuthUpdatedCallbackFn) {
        await this._onAuthUpdatedCallbackFn();
      }

    } else {
      console.error("No okta auth object, maybe are you server side?");
      throw new Error("No okta auth object, maybe are you server side?")
    }
  }

  /**
  * Update the user profile.
  * 
  * @param newUserInfo The updated user profile
  * @returns A promise containing the updated user profile.
  */
  public async updateUserProfile(newUserInfo: IUserInfo): Promise<IUserInfo> {
    if (this._oktaAuth) {

      const updatedUser = await this._onUpdateUserProfileFn(newUserInfo);

      if (updatedUser) {
        this._userInfo.update((value) => {

          if (!value) {
            throw new Error("Error updating user profile, there is nothing to update since we have no profile loaded");
          }

          return {
            bsid: value.bsid,
            id: value.id,
            fullname: `${updatedUser.name} ${updatedUser.surname}`,
            name: updatedUser.name,
            surname: updatedUser.surname,
            email: value.email,
            phone: updatedUser.phone,
            birthday: updatedUser.birthday,
            address: updatedUser.address,
            city: updatedUser.city,
            postalCode: updatedUser.postalCode,
            organization: updatedUser.organization,
            sector: updatedUser.sector,
            function: updatedUser.function,
            subscribe: updatedUser.subscribe,
            businessUseConsent: updatedUser.businessUseConsent,
            profilingConsent: updatedUser.profilingConsent
          }
        });

        const userInfo = get(this._userInfo);

        if (userInfo) {
          return userInfo;
        }
      }

      console.error("Error updating user profile, there is nothing to update since we have no profile loaded");
      throw new Error("Error updating user profile, there is nothing to update since we have no profile loaded");
    } else {
      console.error("No okta auth object, maybe are you server side?");
      throw Error("No okta auth object, maybe are you server side?")
    }
  }
}

